import Header from "./components/Header/Header";
import Main from "./components/Main/Main";


function App() {

  return (
    <div className="App">
      <div className="container">
        <Header />
        <Main />
      </div>
    </div>
  );
}

export default App;
